import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataCatalogApiService, RfidApiService } from '@services/api';
import { AutoCompleteService } from '@services/autocomplete/autocomplete.service';
import { CustomValidatorsService } from '@services/custom-validators/custom-validators.service';
import { InstructionUtilsService } from '@services/instructions/instruction-service-utils.service';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { IAutoComplete } from 'src/app/interfaces/autocomplete/iauto-complete';
import { ActiveInstructionFilters, LocationSimplifiedDto, PartDto, RegionDto } from 'src/app/models';

@Component({
  selector: 'instruction-filter',
  templateUrl: './instruction-filter.component.html',
  styleUrls: ['./instruction-filter.component.scss'],
})
export class InstructionFilterComponent implements OnInit, OnChanges, OnDestroy {
  isRawQuery: boolean = false;
  autoComplete?: IAutoComplete;
  instructionStates: { key: any; value: string }[];
  instructionTypes: { key: any; value: string }[];

  // Form controls to get selection lists
  formGroup = new FormGroup({
    idCtrl: new FormControl(''),
    typesCtrl: new FormControl([]),
    statesCtrl: new FormControl([]),
    tagCtrl: new FormControl('', [this.customValidators.endIsValid([','])]),
    epcCtrl: new FormControl(''),
    timeCtrl: new FormControl(undefined, [Validators.min(1)]),
    originCtrl: new FormControl('', [this.customValidators.endIsValid([','])]),
    destinationCtrl: new FormControl('', [this.customValidators.endIsValid([','])]),
    partCtrl: new FormControl('', [this.customValidators.endIsValid([','])]),
  });
  queryCtrl = new FormControl('');

  // Variables for manipulate filters.
  private allLocations: LocationSimplifiedDto[] = [];
  private allDestinations: RegionDto[] = [];
  private allParts: PartDto[] = [];
  filteredOrigins: string[] = [];
  filteredDestinations: string[] = [];
  filteredParts: string[] = [];
  filteredTags?: Observable<string[]>;

  // Variables for subscriptions.
  private subOrigin?: Subscription;
  private subDestination?: Subscription;
  private subPart?: Subscription;

  @Output() handleRequestButton = new EventEmitter<string>();
  @Input() isLoading: boolean = false;
  @Input() activeControllers: ActiveInstructionFilters = {
    id: true,
    type: true,
    state: true,
    rfid: true,
    epc: true,
    time: true,
    origin: true,
    destination: true,
    part: true,
  };

  constructor(
    private customValidators: CustomValidatorsService,
    private dataCatalogApiService: DataCatalogApiService,
    private autoCompleteService: AutoCompleteService,
    private rfidService: RfidApiService,
    private instructionUtilsService: InstructionUtilsService
  ) {
    this.instructionStates = this.instructionUtilsService.getInstructionStates();
    this.instructionTypes = this.instructionUtilsService.getInstructionTypes();
  }

  ngOnDestroy(): void {
    this.autoComplete!.destroy();
    this.subDestination!.unsubscribe();
    this.subOrigin!.unsubscribe();
    this.subPart!.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isLoading) {
      this.queryCtrl.disable();
      this.formGroup.disable();
    } else {
      this.queryCtrl.enable();
      this.formGroup.enable();
    }
  }

  async ngOnInit(): Promise<void> {
    await this.requestInitialData();
    this.setFormCtrlsSubscribe();
  }

  toggleQueryMode() {
    if (!this.isRawQuery) {
      this.queryCtrl.setValue(this.getQueryFromControllers(false));
    } else {
      this.transformQueryInFilters();
      this.queryCtrl.setValue('');
    }

    this.isRawQuery = !this.isRawQuery;
  }

  async applyQuery() {
    this.handleRequestButton.emit(this.isRawQuery ? this.normalizeQuery() : this.getQueryFromControllers(true));
  }

  // Methods for initial configuration of page.
  private async requestInitialData() {
    this.allParts = await this.dataCatalogApiService.getAllPartsFromCache();
    this.allLocations = await this.dataCatalogApiService.getSimplifiedLocationsData().toPromise();
    this.allDestinations = await this.dataCatalogApiService.getAllDestinationsFromCache();
    this.autoComplete = await this.autoCompleteService.createInstructionAutoComplete(this.queryCtrl);
  }

  private setFormCtrlsSubscribe() {
    const originCtrl = this.formGroup.get('originCtrl');
    const destinationCtrl = this.formGroup.get('destinationCtrl');
    const partCtrl = this.formGroup.get('partCtrl');
    const tagCtrl = this.formGroup.get('tagCtrl');

    if (originCtrl) {
      this.subOrigin = originCtrl.valueChanges.subscribe({
        next: value => {
          const allOrigins = this.allLocations.map(l => l.name);
          this.filteredOrigins = this.filterValue(value, allOrigins).map(v => v.toUpperCase());
        },
      });
    }
    if (destinationCtrl) {
      this.subDestination = destinationCtrl.valueChanges.subscribe({
        next: value => {
          const allDestinations = this.allLocations.map(l => l.name).concat(this.allDestinations.map(d => d.name));
          this.filteredDestinations = this.filterValue(value, allDestinations).map(v => v.toUpperCase());
        },
      });
    }
    if (partCtrl) {
      this.subPart = partCtrl.valueChanges.subscribe({
        next: value => {
          const allPartsStr = this.allParts.map(p => p.number);
          this.filteredParts = this.filterValue(value, allPartsStr);
        },
      });
    }
    if (tagCtrl) {
      this.filteredTags = tagCtrl.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(async value => {
          if (value !== '') {
            const splittedValue = value.split(', ');
            const searchValue = splittedValue.pop() ?? '';
            const rfids = (await this.rfidService.getRfidTags(searchValue, 1, 50)).rfidTagInfoDtos;

            if (splittedValue.length >= 1) {
              return rfids.map(r => splittedValue.join(',') + ', ' + r.barcode);
            }
            return rfids.map(r => r.barcode);
          }
          return [];
        })
      );
    }
  }

  private transformQueryInFilters() {
    const tokens = this.autoComplete!.getTokens();
    const mapCtrl: Map<string, string[]> = new Map<string, string[]>();

    // Separate filters from query.
    for (const token of tokens) {
      // Validate token.
      const tokenData = token.getData();

      if (mapCtrl.has(tokenData.filterName)) {
        const valuesOnMap = mapCtrl.get(token.getData().filterName)!;
        const missingValues = tokenData.filterOptions.filter(o => !valuesOnMap.includes(o));

        mapCtrl.set(tokenData.filterName, valuesOnMap.concat(missingValues));
      } else {
        mapCtrl.set(tokenData.filterName, tokenData.filterOptions);
      }
    }

    // Reset form group values
    this.formGroup.setValue({
      idCtrl: '',
      typesCtrl: [],
      statesCtrl: [],
      tagCtrl: '',
      epcCtrl: '',
      timeCtrl: null,
      originCtrl: '',
      destinationCtrl: '',
      partCtrl: '',
    });

    // Set values on form controls.
    for (const entry of mapCtrl.entries()) {
      switch (entry[0]) {
        case 'type': {
          const typeValues = this.getObjectsFromValues(entry[1], this.instructionTypes);
          this.formGroup.get('typesCtrl')!.setValue(typeValues);
          break;
        }
        case 'state': {
          const stateValues = this.getObjectsFromValues(entry[1], this.instructionStates);
          this.formGroup.get('statesCtrl')!.setValue(stateValues);
          break;
        }
        case 'barcode':
          this.formGroup.get('tagCtrl')!.setValue(entry[1].join(', ').trim());
          break;
        case 'epc':
          this.formGroup.get('epcCtrl')!.setValue(entry[1].toString().trim());
          break;
        case 't':
          this.formGroup.get('timeCtrl')!.setValue(Number(entry[1].toString()));
          break;
        case 'origin':
          this.formGroup.get('originCtrl')!.setValue(entry[1].join(', ').trim());
          break;
        case 'destination':
          this.formGroup.get('destinationCtrl')!.setValue(entry[1].join(', ').trim());
          break;
        case 'part':
          this.formGroup.get('partCtrl')!.setValue(entry[1].join(', ').trim());
          break;
        case 'id':
          this.formGroup.get('idCtrl')!.setValue(entry[1].join(', ').trim());
      }
    }
  }

  private getObjectsFromValues(entryValues: string[], searchArray: { key: any; value: string }[]) {
    const values = [];

    for (const value of searchArray) {
      if (entryValues.includes(value.value)) {
        values.push(value);
      }
    }

    return values;
  }

  private getQueryFromControllers(isForApi: boolean) {
    const controllers = Object.entries(this.formGroup.controls);
    let query = '';

    for (const c of controllers) {
      const typeValue = typeof c[1].value;

      if (
        c[1].value &&
        ((typeValue === 'string' && c[1].value !== '') ||
          (typeValue === 'object' && c[1].value.length > 0) ||
          (typeValue === 'number' && c[1].value > 0))
      ) {
        if (query !== '') {
          query += ' AND ';
        }

        switch (c[0]) {
          case 'typesCtrl':
            query += 'type=' + this.getDataFromObject(c[1].value, isForApi).join(',');
            break;
          case 'statesCtrl':
            query += 'state=' + this.getDataFromObject(c[1].value, isForApi).join(',');
            break;
          case 'tagCtrl':
            query += 'barcode=' + c[1].value.trim().replace(', ', ',');
            break;
          case 'epcCtrl':
            query += 'epc=' + c[1].value.trim();
            break;
          case 'timeCtrl':
            query += 't=' + c[1].value;
            break;
          case 'originCtrl':
            query += 'origin=' + c[1].value.trim().replace(', ', ',').toUpperCase();
            break;
          case 'destinationCtrl':
            query += 'destination=' + c[1].value.trim().replace(', ', ',').toUpperCase();
            break;
          case 'partCtrl':
            query += 'part=' + c[1].value.trim().replace(', ', ',');
            break;
          case 'idCtrl':
            query += 'id=' + c[1].value.trim().replace(', ', ',');
            break;
        }
      }
    }

    return query;
  }

  private getDataFromObject(objects: { key: any; value: string }[], isForApi: boolean) {
    return objects.map(o => (isForApi ? o.key : o.value));
  }

  private normalizeQuery() {
    const tokens = this.autoComplete!.getTokens();

    for (const token of tokens) {
      const tokenData = token.getData();

      if (tokenData.filterName === 'state' || tokenData.filterName === 'type') {
        let normalizedOptions: string[];

        if (tokenData.filterName === 'state') {
          normalizedOptions = this.getKeysFromValues(tokenData.filterOptions, this.instructionStates);
        } else {
          normalizedOptions = this.getKeysFromValues(tokenData.filterOptions, this.instructionTypes);
        }

        tokenData.filterOptions = normalizedOptions;
        token.setStr(`${tokenData.filterName}=${tokenData.filterOptions.join(',')}`);
      }
    }

    return tokens.map(t => t.getStr()).join(' AND ');
  }

  private getKeysFromValues(entryValues: string[], searchArray: { key: any; value: string }[]) {
    const values: string[] = [];

    for (const value of searchArray) {
      if (entryValues.includes(value.value)) {
        values.push(value.key);
      }
    }

    return values;
  }

  private filterValue(value: string, allValues: string[]) {
    const splittedValue = value.split(', ');
    const searchValue = splittedValue.pop() ?? '';
    const filteredValues = allValues.filter(l => l.toLowerCase().includes(searchValue.toLowerCase()));

    if (splittedValue.length >= 1) {
      return filteredValues.slice(0, 100).map(f => splittedValue.join(',') + ', ' + f);
    }
    return filteredValues.slice(0, 100);
  }
}
