/*
 * File: \src\app\pages\instructions\list\instruction.list.component.ts
 * Project: boxcar-console
 * Created Date: 2022-05-24 10:05:12
 * Author: Jorge Felix (jfelix@vonbraunlabs.com)
 * -----
 * Copyright 2022 CPA Wernher von Braun
 * -----
 * HISTORY:
 * Date      	By	Comments
 * ----------	---	---------------------------------------------------------
 * 2022-05-24	JF	Instruction list with filter.
 */

import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { boxCarCoreErrorDetails, BoxCarHttpErrorResponse, InstructionApiService } from '@services/api';
import { InstructionDto, InstructionSearchQuery, InstructionState, InstructionType } from 'src/app/models';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AppToastService, NoticeDialogService } from '@services/index';
import { LoadingIndicatorService } from '@services/loading/loading.service';
import { ExportCsvService } from '@services/export/csv.service';
import { InstructionUtilsService } from '@services/instructions/instruction-service-utils.service';

@Component({
  selector: 'instruction-list',
  templateUrl: './instruction.list.component.html',
  styleUrls: ['./instruction.list.component.scss'],
})
export class InstructionListComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  isLoading: boolean = false;
  isChangeStructureOpen: boolean = false;

  columns = [
    {
      key: 'id',
      value: 'Id',
    },
    {
      key: 'sector',
      value: this.translate.instant('instructions.list.sector'),
    },
    {
      key: 'type',
      value: this.translate.instant('instructions.list.type'),
    },
    {
      key: 'state',
      value: this.translate.instant('instructions.list.state'),
    },
    {
      key: 'activeBarcode',
      value: this.translate.instant('instructions.list.barcode'),
    },
    {
      key: 'boxType',
      value: this.translate.instant('instructions.list.boxType'),
    },
    {
      key: 'partNumber',
      value: this.translate.instant('instructions.list.partNumber'),
    },
    {
      key: 'partName',
      value: this.translate.instant('instructions.list.partName'),
    },
    {
      key: 'source',
      value: this.translate.instant('instructions.list.source'),
    },
    {
      key: 'destination',
      value: this.translate.instant('instructions.list.destination'),
    },
    {
      key: 'user',
      value: this.translate.instant('instructions.list.user'),
    },
    {
      key: 'creationDate',
      value: this.translate.instant('instructions.list.creationDate'),
    },
    {
      key: 'lastUpdate',
      value: this.translate.instant('instructions.list.updateDate'),
    },
  ];

  allColumnKeys: string[] = [];

  // Data table options.
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  selectedColumns = new FormControl();

  paginator = {
    length: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 100],
    pageIndex: 0,
  };

  private request: InstructionSearchQuery = {
    query: '',
    qtyPerPage: 10,
    pageNum: 0,
  };

  constructor(
    private instructionApiService: InstructionApiService,
    private translate: TranslateService,
    private noticeDialog: NoticeDialogService,
    private loadingIndicatorService: LoadingIndicatorService,
    private exportService: ExportCsvService,
    private toastService: AppToastService,
    private instructionUtils: InstructionUtilsService
  ) {
    this.dataSource = new MatTableDataSource([] as InstructionDto[]);

    // Get all columns and set on selected columns.
    this.columns.forEach(column => {
      this.allColumnKeys = this.allColumnKeys.concat(column.key);
    });
    this.selectedColumns.setValue(this.allColumnKeys);
  }

  async ngOnInit(): Promise<void> {
    await this.requestInstructions();
  }

  // Methods for retrieve data from API.
  async requestInstructions() {
    this.isLoading = true;

    await this.instructionApiService
      .searchInstructions(this.request)
      .toPromise()
      .then((instructions: InstructionDto) => {
        this.dataSource = new MatTableDataSource(instructions.instructions);
        this.paginator.length = instructions.instructionsQuantity;
      })
      .catch((error: BoxCarHttpErrorResponse) => {
        this.noticeDialog.show(...boxCarCoreErrorDetails(error));
      });

    this.isLoading = false;
  }

  changePaginator(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.request.qtyPerPage = this.paginator.pageSize;
    this.request.pageNum = this.paginator.pageIndex + 1;

    this.requestInstructions();
  }

  async getInstructionsFromFilter(queryStr: string) {
    this.request.query = queryStr;

    await this.requestInstructions();
  }

  // Methods for manipulate table data.
  stateNameFromValue(state: InstructionState): string | undefined {
    let value: string | undefined = this.instructionUtils.getInstructionStates().find(p => p.key == state)?.value;
    if (value === undefined) {
      value = '<not mapped>';
    }

    return value;
  }

  typeNameFromValue(type: string): string | undefined {
    if (type == `${InstructionType.storing}`) {
      return this.translate.instant('instructions.types.storing');
    } else if (type == `${InstructionType.picking}`) {
      return this.translate.instant('instructions.types.picking');
    } else if (type == `${InstructionType.reallocation}`) {
      return this.translate.instant('instructions.types.reallocation');
    }
    return type;
  }

  filterModelValue(value: string) {
    return value.toLowerCase().includes('__null') ? this.translate.instant('boxes.genericBox') : value;
  }

  filterBarCodeValue(value: string) {
    return value.toLowerCase().includes('__null') ? 'ND' : value;
  }

  formatDate(strDate: string): string {
    if (strDate) {
      return new Date(strDate + 'Z').toLocaleString('default');
    }
    return this.translate.instant('instructions.list.invalidData');
  }

  // Methods for change table structure.
  selectAllColumns() {
    this.selectedColumns.setValue(this.allColumnKeys);
  }

  removeAllColumns() {
    this.selectedColumns.setValue([]);
  }

  toggleChangeStructure() {
    this.isChangeStructureOpen = !this.isChangeStructureOpen;
  }

  // Methods for export instructions data.
  async exportInstructions() {
    this.loadingIndicatorService.show();
    const instructionsData = await this.getAllInstructionsFromFilter();

    if (instructionsData) {
      const headers = this.columns.map(c => c.value);
      const csvData = instructionsData.instructions.map(i => {
        return {
          id: i.instructionId,
          sector: i.sectors ? i.sectors.join('-') : 'ND',
          type: this.typeNameFromValue(`${i.instructionType}`),
          state: this.stateNameFromValue(i.instructionState),
          barcode: this.filterBarCodeValue(i.activeBarcode),
          containerModel: this.filterModelValue(i.containerModel),
          partNumber: i.partNumber,
          partDesc: i.partDescription,
          origin: i.origin,
          destination: i.destination,
          user: i.user,
          createdAt: this.formatDate(i.createdAt).replace(', ', ' - '),
          lastStateUpdate: this.formatDate(i.lastStateUpdate).replace(', ', ' - '),
        };
      });

      this.exportService.exportCSV(csvData, 'Instructions', headers);
    }

    this.loadingIndicatorService.hide();
  }

  private async getAllInstructionsFromFilter(): Promise<InstructionDto | undefined> {
    const request = { ...this.request };
    const maxQuantity = 16000;

    if (this.paginator.length <= maxQuantity && this.paginator.length > 0) {
      request.qtyPerPage = this.paginator.length;
      request.pageNum = 1;

      return await this.instructionApiService
        .searchInstructions(request)
        .toPromise()
        .then(value => value)
        .catch(_ => {
          this.toastService.error(this.translate.instant('toast.errors.instruction.request'));
          return undefined;
        });
    } else {
      if (this.paginator.length > maxQuantity) {
        this.toastService.error(this.translate.instant('toast.errors.export.maxQuantity'));
      } else {
        this.toastService.error(this.translate.instant('toast.errors.export.minQuantity'));
      }

      return undefined;
    }
  }
}
