<!--
File: \src\app\pages\instructions\list\instruction.list.component.html
Project: boxcar-console
Created Date: 2022-05-24 10:07:54
Author: Jorge Felix (jfelix@vonbraunlabs.com)
-----
Copyright 2022 CPA Wernher von Braun
-----
HISTORY:
Date      	By	Comments
----------	---	---------------------------------------------------------
2022-05-24	JF	Instruction's list page template.
-->

<h1>{{ 'instructions.list.title' | translate }}</h1>

<div class="header">
  <instruction-filter [isLoading]="isLoading" (handleRequestButton)="getInstructionsFromFilter($event)">
  </instruction-filter>
</div>

<div class="btns-container">
  <button type="button" mat-raised-button color="primary" (click)="toggleChangeStructure()">
    {{ 'common.buttons.changeTableStructure' | translate }}
  </button>

  <button class="export-button" mat-raised-button color="primary" (click)="exportInstructions()" [disabled]="isLoading">
    {{ 'common.buttons.exportData' | translate }}
  </button>
</div>

<div class="structure-container" *ngIf="isChangeStructureOpen">
  <h2 class="mt-2 mb-3">
    {{ 'instructions.list.viewConfig' | translate }}
  </h2>

  <div class="filters">
    <mat-form-field class="fullWidthForm" appearance="fill">
      <mat-label>{{ 'instructions.list.columns' | translate }}</mat-label>
      <mat-select [formControl]="selectedColumns" multiple>
        <mat-option *ngFor="let column of columns" [value]="column.key">{{ column.value }}</mat-option>
      </mat-select>
      <mat-hint>
        {{ 'instructions.list.selectColumns' | translate }}
      </mat-hint>
    </mat-form-field>
  </div>

  <br />

  <div class="end-buttons">
    <!-- Select all columns button -->
    <button mat-raised-button color="primary" (click)="selectAllColumns()">
      {{ 'instructions.list.allColumns' | translate }}
    </button>

    <!-- Remove all columns -->
    <button mat-raised-button color="primary" class="ml-2" (click)="removeAllColumns()">
      {{ 'instructions.list.removeColumns' | translate }}
    </button>
  </div>
</div>

<!-- Instructions list -->
<div class="row geral-group" style="margin: 1rem auto">
  <div style="width: 100%">
    <!-- Instructions table -->
    <div class="mat-elevation-z2 loading-container">
      <!-- Loading indicator -->
      <loading-on-page [isLoading]="isLoading"></loading-on-page>

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <caption style="display: none">
          Instructions info
        </caption>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id.</th>
          <td mat-cell *matCellDef="let row">{{ row.instructionId }}</td>
        </ng-container>

        <ng-container matColumnDef="sector">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.sector' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.sectors ? row.sectors.join(', ') : 'ND' }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.type' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ typeNameFromValue(row.instructionType) }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.state' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ stateNameFromValue(row.instructionState) }}</td>
        </ng-container>

        <ng-container matColumnDef="activeBarcode">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.barcode' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ filterBarCodeValue(row.activeBarcode) }}</td>
        </ng-container>

        <ng-container matColumnDef="boxType">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.boxType' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ filterModelValue(row.containerModel) }}</td>
        </ng-container>

        <ng-container matColumnDef="partNumber">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.partNumber' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.partNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="partName">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.partName' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.partDescription }}</td>
        </ng-container>

        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.source' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.origin }}</td>
        </ng-container>

        <ng-container matColumnDef="destination">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.destination' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.destination }}</td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.user' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.user }}</td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.creationDate' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ formatDate(row.createdAt) }}</td>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
          <th mat-header-cell *matHeaderCellDef>{{ 'instructions.list.updateDate' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ formatDate(row.lastStateUpdate) }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="selectedColumns.value"></tr>

        <tr mat-row *matRowDef="let row; columns: selectedColumns.value"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [colSpan]="selectedColumns.value.length">
            {{ 'instructions.list.nodata' | translate }}
          </td>
        </tr>
      </table>

      <paginator-page-select
        [pageSizeOptions]="paginator.pageSizeOptions"
        (page)="changePaginator($event)"
        [length]="paginator.length"
        [pageIndex]="paginator.pageIndex"
        [pageSize]="paginator.pageSize"></paginator-page-select>
    </div>
  </div>
</div>
